/**
 * elements with `overlay` component draw in front of every other elements in the sceen
 *
 * based on https://github.com/supermedium/superframe/tree/master/components/render-order/
 *
 * ⚠ !! Does not work if overlay is set from a mixin !! ⚠
 */
AFRAME.registerSystem("overlay", {
    schema: {type: "array"},

    init: function () {
        this.el.renderer.sortObjects = true;
    },

    update: function () {
        this.overlayOrder = {};
        for (i = 0; i < this.data.length; i++) {
            this.overlayOrder[this.data[i]] = i;
        }
    },
});

AFRAME.registerComponent("overlay", {
    schema: {type: "string"},

    multiple: true,

    init: function () {
        this.checkValid();
        this.set = this.set.bind(this);
        this.el.addEventListener("object3dset", (evt) => {
            if (this.id !== "nonrecursive") {
                evt.detail.object.traverse(this.set);
            }
        });
    },

    update: function () {
        if (this.id === "nonrecursive") {
            this.set(this.el.object3D);
        } else {
            this.el.object3D.traverse(this.set);
        }
    },

    set: function (node) {
        // to allow nested recursive overlays
        // consider this node iff the closest ancestor overlay is this
        if (this.data !== node.el.closest("[overlay]").components.overlay.data) {
            return;
        }

        // String (named order).
        if (isNaN(this.data)) {
            node.renderOrder = this.system.overlayOrder[this.data];
        } else {
            node.renderOrder = parseFloat(this.data);
        }

        if (node.isMesh) node.material.depthTest = false;
    },
    checkValid: function () {
        if (isNaN(this.data) && this.system.overlayOrder[this.data] === undefined) {
            console.error(
                `invalid overlay value '${this.data}'. Must be a number or one of '${Object.keys(
                    this.system.overlayOrder
                )}'`
            );
        }
    },
});

AFRAME.registerComponent("render-order-recursive", {
    init: function () {
        this.el.addEventListener("child-attached", (evt) => {
            evt.detail.el.setAttribute("render-order", this.el.getAttribute("render-order"));
        });
    },
});
